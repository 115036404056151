.header-social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: rem(10);

  @include mediaBigDesktop {
    gap: big(10);
  }

  @include mediaLaptop {
    gap: rem(5);
  }

  @include mediaTablet {
    gap: rem(10);
  }

  @include mediaMobile {
    gap: rem(8);
  }

  &__item {
    &_search {
      & .button {
        --button-icon-color: var(--text-dark-primary);
      }
    }

    &_favorites {
      & .button {
        --button-icon-color: var(--accent-secondary-default);
        --button-bg-color: var(--bg-white);
        --button-bg-color-hover: var(--accent-secondary-default);
      }
    }
    
    &_text {
      @include mediaMobile {
        display: none;
      }

      & .button {
        --button-text-color: var(--accent-secondary-default);
        --button-text-color-hover: var(--text-light-primary);
        --button-icon-color: var(--text-dark-primary);
        --button-icon-color-hover: var(--text-light-primary);
        --button-bg-color: var(--bg-white);
        --button-bg-color-hover: var(--accent-secondary-hover);

        background-color: var(--bg-white);
      }
    }

    &_phone {
      display: none;

      @include mediaTablet {
        display: flex;
      }

      & .button {
        --button-icon-color: var(--text-dark-primary);
        --button-icon-color-hover: var(--text-light-primary);
        --button-bg-color: var(--bg-white);
        --button-bg-color-hover: var(--text-dark-primary);

        background-color: var(--bg-white);
      }
    }

    &_whatsapp,
    &_tg {
      & .button {
        --button-icon-color: var(--text-light-primary);
        --button-icon-color-hover: var(--accent-primary-default);
        --button-bg-color: var(--accent-primary-default);
        --button-bg-color-hover: var(--bg-white);

        background-color: var(--button-bg-color);

        &[data-button-animation] {
          --button-icon-color-hover: var(--accent-primary-default);
        }

        @include hover {
          background-color: var(--button-bg-color);

          & .icon {
            fill: var(--button-icon-color-hover);
          }
        }
      }
    }

    &_whatsapp {
      --accent-primary-default: #60D669;
    }

    &_tg {
      --accent-primary-default: #2AABEE;
    }
  }
}
