@import "../../../mixins";

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;

  padding: rem(30) 0;
  width: 100%;

  transition: background-color var(--animation-medium),
    box-shadow var(--animation-default);

  @include mediaBigDesktop {
    padding: big(30) 0;
  }

  @include mediaLaptop {
    padding: rem(20) 0;
  }

  @include mediaTablet {
    padding: rem(15) 0;
  }

  @include mediaMobile {
    padding: rem(12) 0;
  }

  & .header-social {
    &__item {
      &_favorites {
        position: relative;
        
        & .header-social {
            &__count {
              position: absolute;
              top: rem(-7);
              right: 0;
              z-index: 2;
            
              width: rem(18);
              height: rem(18);
              display: flex;
              align-items: center;
              justify-content: center;
            
              font: var(--font-body-XXS-m);
            
              background-color: var(--accent-secondary-default);
              color: var(--text-light-primary);
              border-radius: var(--radius-infinity);
            
              transform: scale(0);
              pointer-events: none;
            
              transition: color var(--animation-default),
                background-color var(--animation-default),
                transform var(--animation-default);
              
              @include mediaBigDesktop {
                top: big(-7);
                right: 0;
                
                width: big(24);
                height: big(24);
              }
            
              @include mediaDesktop {
                top: rem(-7);
                right: 0;
                
                width: rem(24);
                height: rem(24);
              }
            
              @include mediaLaptop {
                width: rem(18);
                height: rem(18);
              }
    
              &_active {
                transform: scale(1);
              }
            }
        }
        
        /*@include media(350px) {
          display: none;
        }*/
      }

      &_text,
      &_whatsapp,
      &_tg {
        @include media(530px) {
          display: none;
        }
      }
    }
  }

  &.header {
    &_background {
      background-color: var(--bg-white);

      & .header-social {
        &__item {

          &_text,
          &_phone,
          &_favorites {
            & .button {
              --button-bg-color: var(--stroke-light);
            }
          }
        }
      }
    }

    &_shadow {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.10);

      @include mediaBigDesktop {
        box-shadow: 0 big(10) big(20) 0 rgba(0, 0, 0, 0.10);
      }
    }
  }

  &__container {
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: rem(20);

      @include mediaBigDesktop {
        gap: big(20);
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(40);

    @include mediaBigDesktop {
      gap: big(40);
    }

    @include mediaDesktop {
      gap: rem(30);
    }

    @include mediaLaptop {
      gap: rem(20);
    }

    @include mediaMobile {
      gap: rem(15);
    }
  }

  &__call {
    @include mediaTablet {
      display: none;
    }

    &.button {
      --button-icon-color: var(--text-dark-primary);
      --button-icon-color-hover: var(--accent-primary-default);
      --button-text-color: var(--text-dark-primary);
      --button-text-color-hover: var(--accent-primary-default);
    }
  }

  &__burger {
    display: none;

    @include mediaTablet {
      display: flex;
    }

    &.button {
      --button-bg-color: transparent;
      --button-bg-color-hover: var(--accent-primary-default);
      --button-icon-color: var(--accent-primary-default);
      --button-icon-color-hover: var(--accent-primary-default);
    }
  }

  &__nav.nav {
    @include mediaTablet {
      position: absolute;
      top: 100%;
      left: 0;

      padding: rem(40) var(--container-offset);
      width: 100%;
      height: calc(100vh - var(--global-header-height));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(50);

      background-color: var(--bg-white);
      overflow: auto;
      visibility: hidden;
      opacity: 0;

      transition: opacity var(--animation-medium),
        visibility var(--animation-medium);
    }

    @include mediaMobile {
      padding: rem(15) var(--container-offset) rem(51);
      gap: rem(15);
    }

    &.burger-menu {
      visibility: visible;
      opacity: 1;
    }

    & .nav {
      &__list {
        @include mediaTablet {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: rem(20);
        }

        @include mediaMobile {
          gap: rem(15);
        }
      }

      &__item {
        @include mediaTablet {
          padding-bottom: rem(20);
          width: 100%;

          border-bottom: solid 1px var(--stroke-light);

          @include hover {
            & .nav {
              &__link {
                & .icon {
                  transform: unset;
                }
              }

              &__children {
                box-shadow: none;
              }
            }
          }
        }

        @include mediaMobile {
          padding-bottom: rem(15);
        }
      }

      &__link {
        @include mediaTablet {
          width: 100%;
          display: block;

          color: var(--text-dark-primary);
          font: var(--font-card-title);
          letter-spacing: calc(-1em / 100);
        }

        @include mediaMobile {
          font: var(--font-card-title-L);
          letter-spacing: unset;
        }

        &::before {
          @include mediaTablet {
            @include pseudo(10, false, true);
          }
        }

        & .icon {
          @include mediaTablet {
            right: rem(-9);
            left: unset;

            padding: rem(9);
            width: rem(38);
            height: rem(38);

            fill: var(--text-dark-tertiary);

            transition: fill var(--animation-default),
              transform var(--animation-default);

            pointer-events: all;
          }

          @include mediaMobile {
            width: rem(36);
            height: rem(36);
          }

          &.more-active {
            @include mediaTablet {
              fill: var(--accent-primary-default);

              transform: rotate(180deg);
            }
          }
        }
      }

      &__children {
        @include mediaTablet {
          position: static;
          margin: 0;

          padding: 0;
          padding-left: rem(10);
          width: 100%;
          height: 0;

          overflow: hidden;
          visibility: visible;
          opacity: 1;

          pointer-events: all;
        }

        & .nav {
          &__item {
            @include mediaTablet {
              padding: rem(10) 0;

              border-bottom-width: 0;
            }
          }

          &__link {
            @include mediaTablet {
              padding: 0;

              font: var(--font-body-XL-m);

              @include hover {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}

@import "header-social",
"nav";
