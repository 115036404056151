.nav {
  padding: rem(15) 0;
  width: fit-content;

  @include mediaBigDesktop {
    padding: big(15) 0;
  }

  @include mediaDesktop {
    padding: rem(15) 0;
  }

  @include mediaLaptop {
    padding: rem(11) 0;
  }

  @include mediaTablet {
    display: none;
  }

  &__list {
    margin: 0;

    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(50);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(50);
    }

    @include mediaDesktop {
      gap: rem(40);
    }

    @include mediaLaptop {
      gap: rem(30);
    }
  }

  &__item {
    position: relative;

    @include hover {
      & > .nav {
        &__link {
          color: var(--accent-primary-default);

          & .icon {
            transform: rotate(180deg);
          }
        }

        &__children {
          margin-top: 0;

          box-shadow: var(--shadow-large);
          visibility: visible;
          opacity: 1;

          pointer-events: all;
        }
      }
    }

    &_active {
      & .nav__link {
        font-weight: 700;
      }
    }

    & .select-input {
      @include hover {
        & .select-current__icon {
          fill: var(--accent-primary-default);
        }
      }

      & .select-current {
        margin: 0;

        padding: 0;
        min-height: unset;

        background: transparent;
        border: none;
        overflow: visible;

        &::before {
          @include pseudo(25, false, true);

          @include mediaDesktop {
            @include pseudo(20, true, false);
          }

          @include mediaLaptop {
            @include pseudo(15, true, false);
          }
        }

        & .icon {
          transform: rotate(0);
        }
      }

      & .select-answer {
        width: auto;

        overflow: visible;

        &__item {
          @include hover {
            z-index: 2;
          }
        }

        & .nav {
          &__item {
            position: relative;

            & .icon {
              position: unset;

              transform: rotate(-90deg);
            }
          }

          &__link {
            gap: rem(5);

            @include mediaBigDesktop {
              gap: big(5);
            }

            @include mediaLaptop {
              gap: rem(4);
            }
          }

          &__children {
            top: 0;
            left: calc(100% + #{rem(10)});

            @include mediaBigDesktop {
              left: calc(100% + #{big(10)});
            }
          }
        }
      }
    }
  }

  &__link {
    position: relative;

    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: var(--text-dark-primary);
    font: var(--font-body-M-m);
    text-decoration: none;
    white-space: nowrap;

    transition: color var(--animation-default);

    &::before {
      @include pseudo(25, false, true);

      @include mediaDesktop {
        @include pseudo(20, true, false);
      }

      @include mediaLaptop {
        @include pseudo(15, true, false);
      }
    }

    & .icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(100% + #{rem(5)});
      margin: auto;

      fill: var(--accent-primary-default);

      transition: transform var(--animation-default);

      @include mediaBigDesktop {
        left: calc(100% + #{big(5)});
      }

      @include mediaDesktop {
        width: rem(18);
        height: rem(18);
      }

      @include mediaLaptop {
        width: rem(14);
        height: rem(14);
      }

      @include mediaLaptop {
        left: calc(100% + #{rem(4)});
      }
    }
  }

  &__children {
    position: absolute;
    top: calc(100% + #{rem(20)});
    left: 0;
    margin: 0;
    margin-top: rem(-10);
    max-height: rem(516);
    
    padding: rem(12) 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    list-style: none;

    background-color: var(--bg-white);
    border-radius: var(--radius-tertiary);
    visibility: hidden;
    opacity: 0;
    overflow-y: auto;
    overscroll-behavior: contain;

    transition: box-shadow var(--animation-medium),
      margin-top var(--animation-default),
      opacity var(--animation-default),
      visibility var(--animation-default);

    pointer-events: none;
    
    @include scrollbar();

    @include mediaBigDesktop {
      top: calc(100% + #{big(20)});

      padding: big(12) 0;
      max-height: big(516);
    }

    @include mediaDesktop {
      top: calc(100% + #{rem(15)});
      
      max-height: rem(362);
    }

    @include mediaLaptop {
      top: calc(100% + #{rem(10)});

      padding: rem(10) 0;
      max-height: rem(262);
    }

    & .nav {
      &__item {
        width: auto;
        display: flex;
      }

      &__link {
        padding: rem(13) rem(20);
        width: 100%;

        color: var(--text-dark-primary);
        font: var(--font-body-M-r);

        transition: color var(--animation-default),
          background-color var(--animation-default);

        @include mediaBigDesktop {
          padding: big(13) big(20);
        }

        @include mediaLaptop {
          padding: rem(10.5) rem(17);
        }

        @include hover {
          color: var(--accent-primary-default);

          background-color: var(--bg-light);
        }

        &::before {
          content: unset;
        }
      }
    }
  }
}

.nav-mobile {
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(50);

  @include mediaTablet {
    display: flex;
  }

  @include mediaMobile {
    gap: rem(30);
  }

  &__social {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;

    @include mediaMobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(15);
    }
  }

  &__item {
    padding: rem(20);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(5);

    border: solid 1px var(--stroke-light);

    @include mediaMobile {
      padding: 0;
      padding-bottom: rem(15);
      gap: 0;

      border-width: 0 0 1px 0;
    }

    &:first-child,
    &:nth-child(2) {
      padding-top: 0;
    }

    &:nth-child(2n+1) {
      padding-left: 0;

      border-width: 0 1px 1px 0;

      @include mediaMobile {
        border-width: 0 0 1px 0;
      }
    }

    &:nth-child(2n+2) {
      padding-right: 0;

      border-width: 0 0 1px 0;
    }
  }

  &__title {
    width: 100%;
    display: block;

    color: var(--text-dark-secondary);
    font: var(--font-body-M-r);
  }

  &__link {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-card-title);
    letter-spacing: calc(-1em / 100);
    text-decoration: none;

    transition: color var(--animation-default);

    @include mediaMobile {
      font: var(--font-card-title-L);
      letter-spacing: unset;
    }

    @include hover {
      color: var(--accent-primary-default);
    }
  }

  &__order {
    @include mediaMobile {
      width: 100%;
    }
  }
}

.js-nav-overflow-detect {
  opacity: 0 !important;

  & .nav__link {
    &::before {
      content: none;
    }
  }

  & .contacts-select {
    overflow: hidden;
  }
}
