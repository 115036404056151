@mixin scrollbar {
  &::-webkit-scrollbar {
    width: rem(15);

    @include mediaBigDesktop {
      width: big(15);
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--bg-light);
    border: solid 5px var(--bg-white);

    @include mediaBigDesktop {
      border-width: big(5);
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--stroke-light);
    border-radius: var(--radius-infinity);
    border: 5px solid var(--bg-white);

    cursor: pointer;

    @include mediaBigDesktop {
      border-width: big(5);
    }

    @include hover {
      background-color: var(--accent-primary-default);
    }
  }
}

@mixin scrollbarHorizontal {
  &::-webkit-scrollbar {
    height: rem(15);

    @include mediaBigDesktop {
      height: big(15);
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--bg-light);
    border: solid 5px var(--bg-white);

    @include mediaBigDesktop {
      border-width: big(5);
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--stroke-light);
    border-radius: var(--radius-infinity);
    border: 5px solid var(--bg-white);

    @include mediaBigDesktop {
      border-width: big(5);
    }

    @include hover {
      background-color: var(--accent-primary-default);
    }
  }
}

@mixin scrollbarDelete {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
